//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import No from './no.vue'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: '_child',
          label: 'name'
        }
      }
    },
    styleD: {
      type: Object,
      default: () => {
        return {
          width: ' 300px',
          height: '400px'
        }
      }
    }
  },
  data() {
    return {
      checkedId: '',
      nowThis: null,
      first: true
    }
  },
  methods: {
    dealFnArr(arr, callback = () => {}) {
      arr.map((item) => {
        callback(item)
        if (item._child) {
          this.dealFnArr(item._child, callback)
        }
      })
    },
    // 设置默认项
    setDefaule(item) {
      if (item && item.id == -2) {
        item.id = -1
      }
      this.$nextTick(() => {
        if (item) {
          this.dealFnArr(this.data, (item1) => {
            if (item.id === item1.id) {
              item1.checked = true
              this.nowThis = item1
            } else {
              item1.checked = false
            }
          })
        } else {
          this.nowThis.checked = false
          this.nowThis = null
        }
      })
    },
    clickNode(node) {
      if (this.nowThis) {
        this.nowThis.checked = false
      }
      node.checked = true
      this.nowThis = node
      this.$emit('resultGroup', node)
    }
  },
  components: { No }
}
